import { userRoles } from 'src/constants'

import createLocalStorage from '../local'

const { read, save } = createLocalStorage('session')

export const initialState = read(
  {
    loggedIn: false,
    token: '',
    user: undefined,
    isAdmin: false,
    filters: [],
  },
  !!'obscured'
)

export default {
  setUser: (state = initialState, action) =>
    save(
      {
        ...state,
        user: {
          ...state.user,
          ...action.user,
        },
      },
      !!'obscure'
    ),
  login: (state = initialState, action) =>
    save(
      {
        ...state,
        loggedIn: !!action.token,
        token: action.token,
        user: action.user,
        loggedInAt: Date.now(),
        isAdmin: action.user.userType === userRoles.admin,
      },
      !!'obscure'
    ),
  logout: (state = initialState) =>
    save(
      {
        ...state,
        loggedIn: false,
        token: undefined,
        isAdmin: false,
        user: {
          userType: '_guest',
        },
      },
      !!'obscure'
    ),
  setFilters: (state = initialState, action) =>
    save({ ...state, filters: action }, !!'obscure')
  ,
  setFavorites: (state = initialState, action) =>
    save({ ...state,
      user: {
        ...state.user,
        favorites: action.favorites,
      }}, !!'obscure')
  ,
  addFavorite: (state = initialState, action) => save( {
    ...state,
    user: {
      ...state.user,
      favorites: [...state.user.favorites, ...action.favorites ],
    }}, !!'obscure'),
  deleteFavorite: (state = initialState, action) => save( {
    ...state,
    user: {
      ...state.user,
      favorites: state.user.favorites.filter((f) => f.id !== action.favoriteId),
    }}, !!'obscure'),
  updateFavorite: (state = initialState, action) => save( {
    ...state,
    user: {
      ...state.user,
      favorites: state.user.favorites.map((f) => f.id !== action?.favorite?.id ? f : action.favorite),
    }}, !!'obscure'),
}

import { useState, useEffect } from 'react'

import { validate } from 'src/utils/form'

const useFormState = (schema, initialValues = {}) => {
  const cleanFormData = {
    isValid: false,
    values: initialValues,
    touched: {},
    errors: {},
  }
  const [formState, setFormState] = useState(cleanFormData)

  useEffect(() => {
    validate(schema, formState.values)
      .then(() => {
        if (!formState.isValid) {
          setFormState((prevFormState) => ({
            ...prevFormState,
            isValid: true,
            errors: {},
          }))
        }
      })
      .catch((err) => {
        setFormState((prevFormState) => ({
          ...prevFormState,
          isValid: false,
          errors: err,
        }))
      })
  }, [formState.values, formState.isValid, schema])

  const changeHandler = (change) => {
    // Better check if is event?
    if (change.target) {
      change.persist()
      // eslint-disable-next-line no-param-reassign
      change = { [change.target.name]: change.target.value }
    }

    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        ...change,
      },
      touched: {
        ...prevFormState.touched,
        ...Object.keys(change).reduce((acc, key) => {
          acc[key] = true
          return acc
        }, {}),
      },
    }))
  }

  const cleanForm = (initialValues = {}) => {
    setFormState({
      ...cleanFormData,
      values: { ...cleanFormData.values, ...initialValues },
    })
  }

  return [formState, changeHandler, cleanForm]
}

export default useFormState

import {
  changePassword,
  changeProfile,
  getProfile,
  getUsers,
  updateUser,
  deleteUser,
  getGroups,
  upsertGroup,
  removeGroup,
  exitGroup, restoreUser,
  addFavorite, deleteFavorite, getFavorites, updateFavorite
} from "src/services/api";

export default function ({ session, users, api }, history, state) {
  return {
    getProfile: () => {
      api.loading({ name: 'user/get-profile' })
      return getProfile(state.session.token)
        .then((res) => {
          if (res.error) {
            throw res.error
          }

          session.setUser({ user: res })
        })
        .catch((error) => {
          api.error({ name: 'user/profile', error })
          throw error
        })
        .finally(() => {
          api.finish({ name: 'user/get-profile' })
        })
    },
    changeProfile: (profile) => {
      api.loading({ name: 'user/profile' })
      return changeProfile(profile, state.session.token)
        .then((res) => {
          if (res.error) {
            throw res.error
          }
          api.finish({ name: 'user/profile' })
          session.setUser({ user: profile })
        })
        .catch((error) => {
          api.error({ name: 'user/profile', error })
          throw error
        })
    },
    changePassword: (password) => {
      api.loading({ name: 'user/password' })
      return changePassword(password, state.session.token)
        .then((res) => {
          if (res.error) {
            throw res.error
          }
          api.finish({ name: 'user/password' })
        })
        .catch((error) => {
          api.error({ name: 'user/password', error })
          throw error
        })
    },
    getUsers: () => {
      api.loading({ name: 'user/all' })
      return getUsers(state.session.token)
        .then((res) => {
          if (res.error) {
            throw res.error
          }
          users.setUsers({ users: res })
          api.finish({ name: 'user/all' })
        })
        .catch((error) => {
          api.error({ name: 'user/all', error })
          throw error
        })
    },
    updateUser: (data) => {
      api.loading({ name: 'user/update' })
      const { groups, ...user } = data
      return updateUser({ user, groups }, state.session.token)
        .then((res) => {
          if (res.error) {
            throw res.error
          }

          users.updateUser({ user: res.user })
        })
        .catch((error) => {
          api.error({ name: 'user/update', error })
          throw error
        })
        .finally(() => {
          api.finish({ name: 'user/update' })
        })
    },
    deleteUser: (data) => {
      api.loading({ name: 'user/delete' })
      return deleteUser(data, state.session.token)
        .then((res) => {
          if (res.error) {
            throw res.error
          }
          // users.removeUser({ user: data })
          users.updateUser({ user: res.user })
          api.finish({ name: 'user/delete' })
        })
        .catch((error) => {
          api.error({ name: 'user/delete', error })
          throw error
        })
    },
    restoreUser: (user) => {
      api.loading({ name: 'user/restore' })
      return restoreUser(user, state.session.token)
        .then((res) => {
          if (res.error) {
            throw res.error
          }
          users.updateUser({ user: res.user })
          api.finish({ name: 'user/restore' })
        })
        .catch((error) => {
          api.error({ name: 'user/restore', error })
          throw error
        })
    },
    getGroups: () => {
      api.loading({ name: 'user/group' })
      return getGroups(state.session.token)
        .then((res) => {
          if (res.error) {
            throw res.error
          }

          users.setGroups({ groups: res })
        })
        .catch((error) => {
          api.error({ name: 'user/group', error })
        })
        .finally(() => {
          api.finish({ name: 'user/group' })
        })
    },
    upsertGroup: (data) => {
      api.loading({ name: 'user/group' })
      return upsertGroup(data, state.session.token)
        .then((res) => {
          if (res.error) {
            throw res.error
          }

          users.addGroup({ group: res })
        })
        .catch((error) => {
          api.error({ name: 'user/group', error })
          throw error
        })
        .finally(() => {
          api.finish({ name: 'user/group' })
        })
    },
    removeGroup: (groupId) => {
      api.loading({ name: 'user/group' })
      return removeGroup(groupId, state.session.token)
        .then((res) => {
          if (res.error) {
            throw res.error
          }

          users.removeGroup({ groupId })
        })
        .catch((error) => {
          api.error({ name: 'user/group', error })
          throw error
        })
        .finally(() => {
          api.finish({ name: 'user/group' })
        })
    },
    exitGroup: (groupId) => {
      api.loading({ name: 'user/group' })
      return exitGroup(groupId, state.session.token)
        .then((res) => {
          if (res.error) {
            throw res.error
          }

          users.removeGroup({ groupId })
        })
        .catch((error) => {
          api.error({ name: 'user/group', error })
          throw error
        })
        .finally(() => {
          api.finish({ name: 'user/group' })
        })
    },
    getFavorites: () => {
      api.loading({ name: 'userFavorites/load' })
      return getFavorites(state.session.token)
        .then((res) => {
          if (res.error) {
            throw res.error
          }
          console.log("add favorite", res)
          session.setFavorites({ favorites: [ res ]})
        })
        .catch((error) => {
          api.error({ name: 'userFavorites/load', error })
          throw error
        })
        .finally(() => {
          api.finish({ name: 'userFavorites/load' })
        })
    },
    addFavorite: (favorite) => {
      api.loading({ name: 'userFavorites/update' })
      return addFavorite(favorite, state.session.token)
        .then((res) => {
          if (res.error) {
            throw res.error
          }
          console.log("add favorite", res)
          session.addFavorite({ favorites: [ res ]})
        })
        .catch((error) => {
          api.error({ name: 'userFavorites/update', error })
          throw error
        })
        .finally(() => {
          api.finish({ name: 'userFavorites/update' })
        })
    },
    deleteFavorite: (favoriteId) => {
      api.loading({ name: 'userFavorites/delete' })
      return deleteFavorite(favoriteId, state.session.token)
        .then((res) => {
          if (res.error) {
            throw res.error
          }
          session.deleteFavorite({ favoriteId })
        })
        .catch((error) => {
          api.error({ name: 'userFavorites/delete', error })
          throw error
        })
        .finally(() => {
          api.finish({ name: 'userFavorites/delete' })
        })
    },
    updateFavorite: (favorite) => {
      api.loading({ name: 'userFavorites/delete' })
      return updateFavorite(favorite, state.session.token)
        .then((res) => {
          if (res.error) {
            throw res.error
          }
          session.updateFavorite({ favorite: res })
        })
        .catch((error) => {
          api.error({ name: 'userFavorites/update', error })
          throw error
        })
        .finally(() => {
          api.finish({ name: 'userFavorites/update' })
        })
    },
  }
}

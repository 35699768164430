import React, { useEffect } from "react";
import PropTypes from 'prop-types'

import Button from '@material-ui/core/Button'

import clsx from "clsx";
import { CardHeader, Divider, TextField, Modal } from "@material-ui/core";
import APIError from "../../components/APIError";
import { hasError } from "../../utils/form";
import CardActions from "@material-ui/core/CardActions";
import Card from "@material-ui/core/Card";
import { rest } from "lodash";
import { makeStyles } from "@material-ui/styles";
import useFormState from "../../hooks/useFormState";
import * as yup from "yup";
import CardContent from "@material-ui/core/CardContent";

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 700,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%',
  },
  actions: {
    justifyContent: 'flex-end',
  },
}))

const schema = yup.object().shape({
  name: yup.string().required('Name is required'),
})

const initialValues = {
  name: '',
}
const errorKeys = ['update']
const FavouriteRenameModal = ({ onClose, onSave, open, favorite, className }) => {
  const classes = useStyles()
  const [formState, handleChange, cleanForm] = useFormState(schema, initialValues)
  useEffect(() => {
    if (favorite) {
      cleanForm(favorite)
    }
  }, [open, favorite])
  const handleSubmit = (event) => {
    event.preventDefault()
    onSave(formState.values).then(() => {
        cleanForm()
        onClose()
      })
      .catch((e) => {
        console.log(e);
        // Ignore errors
      })
  }
  return (
    <Modal open={open} onClose={onClose} onSubmit={handleSubmit}>
      <Card {...rest} className={clsx(classes.root, className)}>
        <form autoComplete="off">
          <CardHeader title={'Rename favorite filter'} />
          <Divider />
          <CardContent>
            <APIError keys={errorKeys} />
            <TextField
              fullWidth
              label="Name"
              margin="normal"
              name="name"
              onChange={handleChange}
              value={formState.values.name || ''}
              variant="outlined"
              required
              error={hasError(formState, 'name')}
              helperText={hasError(formState, 'name') ? formState.errors.name : null}
            />
          </CardContent>
          <Divider />
          <CardActions>
            <Button
              size="small"
              color="primary"
              variant="contained"
              type="submit"
            >
              Save
            </Button>
            <Button size="small" color="default" variant="contained" onClick={onClose}>
              Cancel
            </Button>
          </CardActions>
        </form>
      </Card>
    </Modal>
  )
}

FavouriteRenameModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  favorite: PropTypes.any,
  className: PropTypes.string,
}

export default FavouriteRenameModal

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'

import { withStore } from 'src/store'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0.5, 1),
    margin: theme.spacing(1, 0),
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.error.main}`,
    fontFamily: '"Roboto", "Helvetica", "Arial"',
    fontSize: 12,
  },
  list: {
    listStyle: 'none',
  },
}))

const propTypes = {
  className: PropTypes.string,
  keys: PropTypes.arrayOf(PropTypes.string),
  result: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      error: PropTypes.oneOfType([
        PropTypes.arrayOf(
          PropTypes.shape({
            msg: PropTypes.string,
            code: PropTypes.string,
          })
        ),
        PropTypes.shape({
          msg: PropTypes.string,
          message: PropTypes.string,
          code: PropTypes.string,
        }),
      ]),
    })
  ),
  clearError: PropTypes.func,
}

const APIError = ({ result = [], keys = [], clearError, className = '' }) => {
  const classes = useStyles()
  const [lastResult, setResult] = useState(result)
  useEffect(() => {
    if (result.length && result !== lastResult) {
      keys.forEach((name) => {
        clearError({ name })
      })

      setResult(result)
    }
  }, [keys, result.length]) // eslint-disable-line react-hooks/exhaustive-deps

  const isArray = Array.isArray(lastResult)

  if ((isArray && lastResult.length === 0) || (!isArray && !lastResult.error)) {
    return null
  }

  const values = isArray ? lastResult : [lastResult]

  return (
    <>
      {values.map((value, i) => {
        const errors = Array.isArray(value.error) ? value.error : [value.error]

        return (
          <div className={clsx(classes.root, className)} key={String(i)}>
            <ul className={classes.list}>
              {errors.map((error) => (
                <li key={error.msg || error.code}>{error.msg || error.message}</li>
              ))}
            </ul>
          </div>
        )
      })}
    </>
  )
}

APIError.propTypes = propTypes

const mapStateToProps = (_, { api }, { keys }) => {
  return {
    result: api.getErrors(keys),
  }
}

const mapActionsToProps = ({ api: { clearError } }) => {
  return {
    clearError,
  }
}

export default withStore(mapStateToProps, mapActionsToProps)(APIError)

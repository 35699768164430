import { api } from './common'

export const login = (emailAddress, userPassword) =>
  api({
    method: 'POST',
    path: '/auth/login',
    body: {
      emailAddress,
      userPassword,
    },
  })

export const reqResetPassword = (emailAddress) =>
  api({
    method: 'POST',
    path: '/auth/forgot',
    body: {
      emailAddress,
    },
  })

export const checkResetLink = (emailAddress, hash) =>
  api({
    method: 'POST',
    path: `/auth/reset/${emailAddress}/${hash}`,
  })

export const resetPassword = (userPassword, hash) =>
  api({
    method: 'POST',
    path: '/auth/reset',
    body: {
      userPassword,
      hash,
    },
  })

export const register = (body, token) =>
  api({
    method: 'POST',
    path: '/auth/register',
    token,
    body,
  })

export const invite = (body, token) =>
  api({
    method: 'POST',
    path: '/auth/invite',
    token,
    body,
  })

export const getProfile = (token) =>
  api({
    method: 'GET',
    path: '/user/profile',
    token,
  })

export const changeProfile = (profile, token) =>
  api({
    method: 'PATCH',
    path: '/user/profile',
    token,
    body: profile,
  })

export const updateUser = (data, token) =>
  api({
    method: 'PATCH',
    path: `/user/${data.user.id}`,
    token,
    body: data,
  })
export const deleteUser = (user, token) =>
  api({
    method: 'DELETE',
    path: `/user/${user.id}`,
    token,
    body: user,
  })
export const restoreUser = (user, token) =>
  api({
    method: 'PATCH',
    path: `/user/${user.id}/restore`,
    token,
    body: user,
  })
export const changePassword = (password, token) =>
  api({
    method: 'PATCH',
    path: '/user/password',
    token,
    body: {
      userPassword: password,
    },
  })

export const getUsers = (token) =>
  api({
    method: 'GET',
    path: `/user`,
    token,
  })

export const getGroups = (token) =>
  api({
    token,
    method: 'GET',
    path: `/user/group`,
  })

export const upsertGroup = (group, token) =>
  api({
    token,
    method: 'PUT',
    path: `/user/group`,
    body: group,
  })

export const removeGroup = (groupId, token) =>
  api({
    token,
    method: 'DELETE',
    path: `/user/group/${groupId}`,
  })

export const exitGroup = (groupId, token) =>
  api({
    token,
    method: 'DELETE',
    path: `/user/group/${groupId}/exit`,
  })
export const getFavorites = (token) =>
  api({
    token,
    method: 'GET',
    path: `/user/favorite/`,
  })
export const addFavorite = (favorite, token) =>
  api({
    token,
    method: 'PUT',
    path: `/user/favorite`,
    body: favorite,
  })
export const deleteFavorite = (favoriteId, token) =>
  api({
    token,
    method: 'DELETE',
    path: `/user/favorite/${favoriteId}`,
  })
export const updateFavorite = (favorite, token) =>
  api({
    token,
    method: 'POST',
    path: `/user/favorite/${favorite.id}`,
    body: favorite,
  })
